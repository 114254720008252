import React from 'react';
import { PopupButton } from '@typeform/embed-react'
import logo from './logo.png';
import './App.css';
import background from './patterns.png'

function App() {
    return (
        <div className="App">
            <header className="App-header" style={{background: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "center"}}>
                <div className="header-content">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <p>
                        Ecosystems Engineering
                    </p>
                    <PopupButton id="Ma2UHP4h" style={{ fontSize: 20 }} className="header-button">
                        Get In Touch
                    </PopupButton>
                </div>
            </header>
        </div>
    );
}

export default App;
